import Vue from 'vue'
import App from './App.vue'
Vue.config.productionTip = true

// 挂载路由
import router from './router'

// 挂载UI
import './common.less';
import './common-en.less';
import './common-mobile.less';

// 挂载请求器
import request from './plugins/request'
Vue.prototype.request = request;

// 挂载语言包
import lang from './plugins/lang'

// 处理request异常
request.interceptors.response.use( 
  (data) => {
    if(data && data.status !=1 && data.msg){
			alert(data.msg);
      return false;
    }
    return data.data || data;
  }, 
  (error) => {
    console.error(error);
  }
);

// 混入全局辅助方法
import Common from './common'

// 全局图片预览器
import ImagesViewer from '/src/components/ImagesViewer.vue';
Vue.component('ImagesViewer', ImagesViewer);

// 全局图片容器
import CoverWrap from '/src/components/CoverWrap.vue';
Vue.component('CoverWrap', CoverWrap);

// 平滑滚动
import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll,{
	updateHistory: false,
	duration:300
})

// 启动
var main = new Vue({
  render: h => h(App),
  router,
  components: {  },
  mixins: [Common]
});
//.$mount('#app')
console.log(main);

