module.exports = {
	"年度征集":"APD SUBMISSION",
	"投稿入口":"SUBMIT NOW",
	"关于APD":"ABOUT APD",
	"顾问编委":"APD JURY",
	"历届APD":"APD VOLUMES",
	"新闻":"NEWS",
	"投稿信息":"SUBMITTER INFO",
	"退出":"QUIT",
	
	"上传作品":"UPLOAD WORKS",
	"联系邮箱":"E-MAIL ADDRESS",
	"所在城市":"CITY",
	"通信地址":"ADDRESS",
	"联系电话":"TEL",
	"微信":"WECHAT",
	"官网":"OFFICIAL WEBSITE",
	"修改":"EDIT",
	"保存":"SAVE",
	"添加作品":"ADD WORKS",
	"投稿作品":"SUBMISSION WORK",
	"作品名称":"WORK TITLE",
	"作品编号":"WORK NUMBER",
	"状态":"STATUS",
	"暂无内容":"THERE’S NO SUBMISSION HERE",
	"电子邮箱格式有误":"The e-mail format is incorrect.",
	
	"登录":"LOGIN",
	"登录成功":"LOGIN OK",
	"电子邮箱":"E-Mail",
	"密码":"Password",
	"新密码":"Password",
	"新密码确认":"Retype Password",
	"确认密码":"Retype Password",
	"验证码":"Verification Code",
	"*号为必填项":"Items with (*) must be filled out.",
	"忘记密码":"FORGOT PASSWORD",
	"注册账号":"REGISTER",
	"注册登录":"REGISTER",
	"注册":"REGISTER",
	"注册成功":"REGISTER OK",
	"密码必须至少6位，并包含英文字母":"Password must be at least 6 characters and contain English letters",
	"密码已发送,请查收邮箱.":"Please check your e-mail to reset your password.",
	"换一换":"REFRESH",
	"确认登录":"Confirm",
	"修改成功!":"Reset successfully!",
	"保存成功!":"Save successfully!",
	"作品征集已结束，请留意官网最新资讯":"Submission has ended. Please pay attention to the official website's newest info.",
	"请先完善投稿信息":"Please complete the submitter's info first.",
	"操作无效":"Invalid operation.",
	"资料上传中，请稍候...":"Uploading data, please wait...",
	
	
	"找回密码":"RESET PASSWORD",
	"你的密码丢了？请输入您的电子邮件地址。":"Forgot your password? Please enter your email.",
	"您将通过电子邮件收到创建新密码的验证码。":"You will receive a verification code to create a new password via e-mail.",
	"密码重置":"RESET",
	
	
	"年鉴简介":"APD INTRODUCTION",
	"指南下载":"SUBMISSION GUIDE",
	"报名日期":"REGISTRATION DATE",
	"报名资格":"QUALIFICATION",
	"收录标准":"SELECTION STANDARD",
	"作品类别":"CATEGORY",
	"年度主题":"ANNUAL THEME",
	"单元设置":"PROGRAM SETTING",
	"条例细则":"TERMS AND CONDITIONS",
	"合作支持":"COOPERATION AND SUPPORT",
	"联系咨询":"CONTACT US",
	
	"学术顾问":"ACADEMIC CONSULTANT",
	"特邀编委":"EDITORIAL COMMITTEE",
	
	"团队名称":"DESIGN TEAM",
	"投稿单位":"SUBMITTER",
	"设计师个人投稿":"Individual Designer",
	"设计师组合投稿":"Designers Team",
	"设计工作室/公司投稿":"Design Studio",
	"品牌方投稿":"Brand Holder",
	"品牌方名称":"Name of the Brand Holder",
	"设计团队名称":"Name of the Design Team",
	"（请谨慎填写；此项将在年鉴索引页、入选名单、证书等中呈现）":"（Please fill out carefully. It will be shown in the index, shortlists, certificates if selected）",
	"设计师个人姓名":"Name of the Designer",
	"设计师组合主要成员姓名":"Names of the Team’s Major Designers",
	"设计工作室/公司名称":"Name of the Design Studio",
	
	"中文名称": "In Chinese",
	"中文版介绍": "In Chinese",
	"主创人员名单": "CREDIT",
	"九选一": "",
	"二选一": "",
	"作品图片": "IMAGES UPLOAD",
	"作品视频": "VIDEO UPLOAD",
	"创作年份": "YEAR",
	"投稿作品信息": "WORK INFO",
	"确认投稿": "SUBMIT",
	"至少填写一项，最多填写6项，并确保所填信息准确": "Fill out at least one, and at most six",
	"英文名称": "In English",
	"英文版介绍": "In English",
	"选择上传": "optional",
	"项目介绍": "WORK DESCRIPTION",
	"150字符以内":"150 words",
	"200字符以内":"200 words",
	"上传图片1~10张，且第一张默认为封面图片，格式可为JPG/JPEG/PNG（RGB模式，300dpi），尺寸不限，单一图片大小请勿超过10MB。（单击拖动图片可调整图片顺序）":"1~10 images can be uploaded here, and the frist image is the cover image by default. The format can be JPG/JPEG/PNG with RGB mode, 300dpi, unlimited size. A single image file should not exceed 10MB.（Click and drag the images for the order's adjustment）",
	"最多上传一个视频，多媒体创意设计等互动设计建议以剪辑视频形式呈现。动态演示视频时长不超过3分钟，视频文件大小不超过30MB，视频格式不限。":"1 video can be uploaded here.  Kinetic typographies, dynamic posters, interactive effects of multimedia scenes, etc. are recommended to be presented in video form. The duration of the video should not exceed 3 minutes, the size of the video file should not exceed 30MB, and the video format, size, and resolution are not limited.",
	"C、E、H类别最多可上传1个视频，时长不超过3分钟，大小不超过50MB，格式为MP4（H.264视频编码和AAC音频编码）或GIF动态图片":"1 video is allowed to upload in the category C, E, H. The duration does not exceed 3 min., the size does not exceed 50 MB, and the format is MP4 (H.264 video and audio encoding) or GIF motion image.",
	"单文件最多不超过":"A single image file should not exceed ",
	"上传失败,请重新上传":"Uploaded Fail. Please retry again.",
	"主创人员名单 最多填写6项":"[CREDIT] Fill out at least one, and at most six",
	"作品图片至少上传3张":"[IMAGES UPLOAD] Fill out at least 3",
	"投稿成功":"SUCCESSFUL SUBMISSION",
	"稿件已经提交成功！":"You have submitted the work successfully!",
	"如果投稿失败请尝试重新投稿":"Please re-upload or contact us if the ",
	"或者联系客服：apd@sandupublishing.com":"submission failed: apd@sandupublishing.com",
	"返回":"BACK",
	"已提交":"SUBMITTED",
	"确认修改":"CONFIRM",
	"一位投稿者最多只能提交10份作品":"The maximum number of submissions per submitter is 10",
	"是否保存该页面信息?":"Save the page info?",
	
	
	
	"品牌设计":"BRANDING",
	"环境图形":"ENVIRONMENTAL GRAPHICS",
	"文字设计":"TYPE DESIGN",
	"出版物及印刷品设计":"PRINT",
	"海报设计":"POSTER DESIGN",
	"包装设计":"PACKAGE DESIGN",
	"商业插画":"COMMERCIAL ILLUSTRATION",
	"数字设计":"DIGITAL DESIGN",
	"标志设计":"LOGO DESIGN",
	
	"不同意":"DISAGREE",
	"同意":"AGREE",
	
	"评选入口":"REVIEW ENTRANCE",
	"初评入口":"REVIEW ENTRANCE",
	"评选说明":"REVIEW INFO",
	"编委初评":"EDITORIAL COMMITTEE",
	"初评":"EDITORIAL COMMITTEE",
	"★推荐":"★Recommend",
	"7分":"7pt",
	"5分":"5pt",
	"3分":"3pt",
	"0分":"0pt",
	"已打分项目":"Scored Works",
	"个":"pcs",
	"已推荐项目":"Recommended Works",
	
	"评选流程":"SELECTION PROCESS",
	"终选流程":"SELECTION PROCESS",
	"选择<br/>评选类别":"SELECT THE<br/>CATEGORIES<br/>FOR REVIEW",
	"对作品<br/>进行打分":"RATE THE<br/>WORKS",
	"确认<br/>评选清单":"CONFIRM THE<br/>REVIEW<br/>RESULT",
	"提交<br/>评选结果":"SUBMIT THE<br/>REVIEW<br/>RESULT",
	
	"初评日期":"REVIEW DEADLINE",
	"终评日期":"REVIEW DEADLINE",
	"终评":"FINAL REVIEW",
	"终评入口":"REVIEW ENTRANCE",
	"2022年6月16日–6月20日（为期五天）":"2022.06.16–2022.06.20 (5 Days)",
	"征稿截止后的1~2周内（为期五天）":"Within 1-2 Weeks after the Deadline of Submission (5 Days)",
	"初评后的1~2周内（为期两天）":"Within 1-2 Weeks after the Preliminary Review (2 Days)",
	
	"指定账号登录":"LOGIN",
	"以亚太编辑部指定的账号登录。":"Log in to the specific account given by APD editorial department.",
	
	"选择评选类别":"CATEGORIES’ SELECTION",	"点击每一个类别进行作品评选。":"Click to enter the specific categories for selection.",
	
	"投稿作品打分":"SELECT SHORTLISTED WORKS",	"查看作品后，对每个类别的作品进行打分。<br/>（共有0、3、5、7四个分数级别可供选择）":"After reviewing the entries, rate the entries in each category.<br/>(There are four score levels to choose from: 0, 3, 5, and 7.)",
	
	"选择推荐作品":"SELECT RECOMMENDATION WORKS",	"每个类别的特邀编委拥有推荐特权，可按一定比例<br/>选出直接收录到“年度推荐单元”的作品。":"After reviewing the entries, select the entries for Annual Recommendation Program according to a certain percentage.",
	
	"确认评选清单":"CONFIRM THE SELECTION LIST",	"确认评选清单无误后，提交评选结果。":"After confirming the selection list, submit the selection results to the organizer through the website.",
	
	"13位評委<br/>一人20票":"ONE JUROR<br/>HAS 20 VOTES",
	"在所有推荐作<br/>品中投票选出<br/>20件最佳作品":"SELECT 20 BEST<br/>WORKS FROM THE<br/>RECOMMENDATION<br/>WORKS",
	"确认<br/>投票清单":"CONFIRM THE<br/>REVIEW<br/>RESULT",
	"提交<br/>投票结果":"SUBMIT THE<br/>REVIEW<br/>RESULT",
	"投票选出20件最佳作品":"SELECT 20 BEST WORKS",
	"在所有得到推荐的作品中投票选出20件最佳设计作品，并提交评选结果。":"Select 20 best works from the recommendation works.",
	"提交投票结果":"CONFIRM AND SUBMIT",
	"最终入选“年度主题单元”的作品将有得票数量决定。":"The final selection of the “Annual Theme Program” will be determined by the number of votes.",
	"未提交评选结果":"To be Determined",
	"已提交评选结果":"Submitted",
	"未评分":"PENDING",
	"待处理":"PENDING",
	"待投票":"PENDING",
	"已投票":"YES",
	"不投票":"NO",
	"上一个":"Prev.",
	"下一个":"Next",
	
	"下一步，确认投票清单":"Click to Confirm",
	
	"已评选作品清单":"LIST OF SELECTED WORKS",
	"已投票作品清单":"LIST OF VOTED WORKS",
	"类总共投稿":"’s Total Submission: ",
	"件，目前已评选":"pcs, Reviewed Numbers: ",
	" “年度主题单元”目前已投：":"Selected Works for Annual Theme Program: ",
	"件":"pcs",
	"*特别说明：一旦提交评选结果将无法进行更改，请谨慎操作。":"*Hint: Once the selection result is submitted, changes cannot be made, please proceed carefully.",
	"返回继续评选":"Back to Review",
	"返回评选入口":"Back to Entrance",
	"提交评选结果":"Submit My Result",
	"提交成功":"SUBMISSION OK",
	"评选结果已经提交成功！":"The relevant review has been<br/>Submitted successfully!",
	
	"年度主题单元":"ANNUAL THEME<br/>PROGRAM",
	"请为“年度主题单元”选出20件作品，目前已投： ":"Selected Works for Annual Theme Program: ",
	"请您评选以下":"Please select the works in the following",
	"个类别的作品":"categories",
	"此类别的作品已评选打分完毕，请点击“返回”按钮":'You have finished the review of this category. Please Press "BACK".',
	"请为全部作品（":"Please rate all works (",
	"件）打分，当前已完成":" pcs), ",
	"件作品的评分。":" pcs have been rated so far.",
	
	"请选出":"Please select ",
	"件“年度主题单元”作品，目前仅选出了":" works for Annual Theme Program, Currently only ",
	"件“年度主题单元”作品，目前选出了":" works for Annual Theme Program, Currently ",
	"件。":" are selected.",
	"件，":" works have been selected.",
	"您推荐的作品数量超过上限。您最多只能推荐":"The number of works you recommend exceeds the limit. You can only recommend up to ",
	"个作品。":" works.",
	"已超出了限定数量，请调整并再次确认。":"The quantity has exceeded the limit. Please adjust and reconfirm.",
	"取消投票":"CANCEL",
	"更改为投票":"CHANGE TO YES",
}
