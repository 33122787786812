<template>
  <div class="cover" ref="cover" :style="{width,height}" :class="{border}">
		<div class="wrap flex middle center">
			<img class="loading" v-if="loading" src="@/assets/common/loading_d.gif" />
			<img class="img" :class="{animation:!unanimation}" :style="iStyle" v-show="src1" :src="src1"/>
		</div>
	</div>
</template>

<script>
export default {
  name: 'Cover',
  props: {
    src:{
			default:"",
			type:String,
		},
		unanimation:{
			default:false,
			type:Boolean,
		},
		border:{
			default:false,
			type:Boolean,
		},
		width:{
			default:"100%",
			type:String,
		},
		height:{
			default:"100%",
			type:String,
		},
		
  },
  data(){
		let isCDN = !!(window.location.host.indexOf("apd-cdn")===0);
		let src1 = this.src;
		if(src1.indexOf(".gif")>-1){
			src1 = src1.split("?x-oss-process")[0];
		}
		if(isCDN){
			src1 = this.src.replace("apd-resource.oss-cn-shenzhen.aliyuncs.com","apd-resource.oss-accelerate.aliyuncs.com");
		}
    return {
      loading:true,
			iwidth:this.width,
			iheight:this.height,
			iStyle:"",
			src1
    }
  },
	async mounted(){
		if(this.width){
			this.iStyle = "width:" + this.width;
		}
		this.loadImg()
	},
	
  methods:{
		loadImg(){
			console.log("loadImg");
			var img = new Image();
			img.onload = (event)=>{
				var r1 = this.$refs.cover.clientWidth/this.$refs.cover.clientHeight;
				var r2 = img.width/img.height;
				if(r1 >= r2){
					if(this.$refs.cover.clientWidth > img.width){
						this.iStyle = "width:" + img.width + "px";
					} else {
						this.iStyle = "width:100%;";
					}
				} else {
					if(this.$refs.cover.clientHeight > img.height){
						this.iStyle = "height:" + img.height + "px";
					} else {
						this.iStyle = "height:100%;";
					}
				}
				// console.log(this.$refs.cover.clientWidth/this.$refs.cover.clientHeight);
				// console.log(img.width/img.height);
				this.loading = false;
				
			}
			
			img.src = this.src1;
		},
    imgLoad(event){
			// console.log(event)
			// this.loading = false;
		}
  } 
}
</script>

<style scoped lang="less">
.cover{
	position: relative;
	overflow: hidden;
	width:100%;
	height: 40px;	
	&.border{
		border:1px solid #DCDDDD;
	}
	&:hover{
		.wrap{
			.img.animation{
				// top:49%;
			}
		}
	}
	.wrap{
		background-color:#DCDDDD;
		height:100%;
		width:100%;
		.loading,.img{
			// zoom:1;
			position: absolute;
			top:50%;left:50%;
			transform: translate(-50%,-50%);
		}
		.img{
			// width: 100%;
			// min-height: 100%;
		}
	}
}
</style>
