import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)

// 主体框架
const Main = () => import('./views/Main.vue'); 

// 404页
const PageNotFound = () => import('./views/PageNotFound.vue');

// 二级路由载体
const Empty = () => import('./views/Empty.vue'); 

// 首页
const Index = () => import('./views/Index.vue'); 

// 用户模块
const UserLogin = () => import('./views/User/Login.vue');
const UserForget = () => import('./views/User/Forget.vue');
const UserRegister = () => import('./views/User/Register.vue');
const UserHome = () => import('./views/User/Home.vue');
const UserWork = () => import('./views/User/Work.vue');

// 作品预览
const WorkList = () => import('./views/Work/List.vue');
const WorkContent = () => import('./views/Work/Content.vue');
const WorkJuryFirstContent = () => import('./views/Work/JuryFirstContent.vue');

// 赛事简介
const Intro = () => import('./views/Intro.vue');

// 赛事动态
const NewsList = () => import('./views/News/List.vue');
const NewsContent = () => import('./views/News/Content.vue');

// 顾问编委
const JudgesList = () => import('./views/Judges/List.vue');

// 历届APD
const APDList = () => import('./views/APD/List.vue');
const APDContent = () => import('./views/APD/Content.vue');

// 投票入口
const ReferendumList = () => import('./views/Referendum/List.vue');
const ReferendumContent = () => import('./views/Referendum/Content.vue');

// 信息反馈页
const Message = () => import('./views/Message.vue');

// 编委初评
const EditorialAwardTypeList = () => import('./views/Editorial/AwardTypeList.vue');
const EditorialAwardConfirm = () => import('./views/Editorial/AwardConfirm.vue');
const EditorialAwardList = () => import('./views/Editorial/AwardList.vue');
const EditorialInfo = () => import('./views/Editorial/Info.vue');

const routes = [
	{ path: '/', component: Main, children:[
		
			{ path: '/Index', component: Index, meta: { title:"首页", needLogin: false, headerClass:"white" } },
			{ path: '/Message', name: 'Message', component: Message, meta: { title:"提示信息", needLogin: false } },
			
			{ path: '/User', component: Empty, children:[
					{ path: 'Login', component: UserLogin, meta: { title:"登录", needLogin: false, isLoginPage: true, headerClass:"white" } },
					{ path: 'Forget', component: UserForget, meta: { title:"找回密码", needLogin: false, isLoginPage: true, headerClass:"white" } },
					{ path: 'Register', component: UserRegister, meta: { title:"注册", needLogin: false, isLoginPage: true, headerClass:"white" } },
					{ path: 'Home', component: UserHome, meta: { title:"参赛者信息", needLogin: true } },
					{ path: 'Work', component: UserWork, meta: { title:"上传作品", needLogin: true } },
					{ name:"WorkEdit", path: 'Work/Edit', component: UserWork, meta: { title:"修改作品", needLogin: true } },
					{ path: '*', component: PageNotFound, meta: { title:"找不到页面", needLogin: false, headerClass:"white" } },
				]
			},
			
			{ path: '/Intro', component: Intro, meta: { title:"赛事简介", needLogin: false } },
			
			{ path: '/Judges', component: Empty, children:[
					{ path: 'List', component: JudgesList, meta: { title:"顾问编委", needLogin: false } },
					{ path: 'List/:id', component: JudgesList, meta: { title:"顾问编委", needLogin: false } },
					{ path: '*', component: PageNotFound, meta: { title:"找不到页面", needLogin: false } },
				],
			},
			{ path: '/Work', component: Empty, children:[
					{ path: 'List', component: WorkList, meta: { title:"作品列表", needLogin: false } },
					{ path: 'Content/:sid/:id/:step', component: WorkContent, meta: { title:"作品详情", needLogin: false } },
					{ path: 'JuryFirstContent/:sid/:id/:step', component: WorkJuryFirstContent, meta: { title:"作品详情", needLogin: false } },
					{ path: '*', component: PageNotFound, meta: { title:"找不到页面", needLogin: false } },
				],
			},
			
			{ path: '/News', component: Empty, children:[
					{ path: 'List', component: NewsList, meta: { title:"新闻", needLogin: false } },
					{ path: 'Content/:id', component: NewsContent, meta: { title:"新闻内容", needLogin: false } },
					{ path: '*', component: PageNotFound, meta: { title:"找不到页面", needLogin: false } },
				],
			},
			
			{ path: '/APD', component: Empty, children:[
					{ path: 'List', component: APDList, meta: { title:"历届APD", needLogin: false } },
					{ path: 'Content/:id', component: APDContent, meta: { title:"APD", needLogin: false } },
					{ path: '*', component: PageNotFound, meta: { title:"找不到页面", needLogin: false } },
				],
			},
			
			{ path: '/Referendum', component: Empty, children:[
					{ path: 'List', component: ReferendumList, meta: { title:"投票作品列表", needLogin: false } },
					{ path: 'Content', component: ReferendumContent, meta: { title:"投票作品详情", needLogin: false } },
					{ path: '*', component: PageNotFound, meta: { title:"找不到页面", needLogin: false } },
				],
			},
			
			{ path: '/Editorial', component: Empty, children:[
					{ path: 'AwardList/:step/:id', component: EditorialAwardList, meta: { title:"选择作品", needLogin: true } },
					{ path: 'AwardList/:step', component: EditorialAwardList, meta: { title:"选择作品", needLogin: true } },
					{ path: 'AwardConfirm/:step/:id', component: EditorialAwardConfirm, meta: { title:"已评选作品清单", needLogin: true } },
					{ path: 'AwardTypeList/:step', component: EditorialAwardTypeList, meta: { title:"选择评选类别", needLogin: true } },
					{ path: 'Info/:step', component: EditorialInfo, meta: { title:"评选说明", needLogin: true } },
					{ path: '*', component: PageNotFound, meta: { title:"找不到页面", needLogin: false } },
				],
			},
			
			{ path: '*', component: PageNotFound, meta: { title:"找不到页面", needLogin: false } },
		],
	},
	
	{ path: '*', component: PageNotFound, meta: { title:"找不到页面", needLogin: false } },
	
];

const MainRouter = new VueRouter({
  mode: 'hash',
  routes,
})

MainRouter.beforeEach((to, from, next) => {
  console.log(to, from);

  const token = window.localStorage.getItem("token");
	if(to.path == '/'){
		if(window.location.search.indexOf('?hash=')===0){
			window.location.replace('/#'+window.location.search.split("?hash=")[1]);
			return ;
		} else {
			next({
			  path: '/Index'
			});
		}
	} else if (token){
		if (to.path== '/User' || to.matched.some(record => record.meta.isLoginPage)) {
			let userData = window.localStorage.getItem("userData");
			if(userData){
				try{
					userData = JSON.parse(userData);
				}catch(e){
					userData = false;
				}
			}
			if(userData && userData.role == 'JUDGES'){
				next({
				  path: '/Editorial/Info/1'
				});
			}else {
				next({
				  path: '/User/Home'
				});
			}
      
    } else {
      next();
    }
  } else{
    if (to.path== '/User' || to.matched.some(record => record.meta.needLogin)) {  
      next({
        path: '/User/Login',
        query: { redirect: to.fullPath } 
      })
    }  else {
      next();
    }
  }
  
});

function scrollAnimation(currentY, targetY) {
  // 计算需要移动的距离
  let needScrollTop = targetY - currentY
  let _currentY = currentY
  setTimeout(() => {
    // 一次调用滑动帧数，每次调用会不一样
    const dist = Math.ceil(needScrollTop / 10)
    _currentY += dist
    window.scrollTo(_currentY, currentY)
    // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
    if (needScrollTop > 10 || needScrollTop < -10) {
      scrollAnimation(_currentY, targetY)
    } else {
      window.scrollTo(_currentY, targetY)
    }
  }, 1)
}

const title = window.document.title.replace("Loading - ","");
MainRouter.afterEach(to => {
  var temp = [];
  if (to.matched){
    to.matched.forEach(m=>{
      if(m.meta && m.meta.title){
        temp.push(m.meta.title);
      }
    });
  }
  window.document.title = temp.length > 0 ? window.L(temp.join(" / ")) + " - " + window.L(title) :  window.L(title);
  scrollAnimation(window.scrollY,0);
})
export default MainRouter;