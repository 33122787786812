import axios from 'axios'

// 创建 axios 实例
const request = axios.create({
  baseURL: '/',
	withCredentials: true,
  timeout: 12000, // 请求超时时间
});
// 添加响应拦截器
request.interceptors.response.use(
  (response) => {
    return response.data;
  }, 
  (error) => {
    console.error(error);
  }
);

//公用头部
request.defaults.headers.common["token"] = window.localStorage.getItem("token");
request.defaults.headers.common["pk"] = process.env.VUE_APP_PK.toLowerCase();
request.defaults.timeout = 60000;
request.defaults.withCredentials = true;

//定义万能字典
request.zd = {
	async get(id){
		var result;
		if(id){
			result = await request.get(`/api/v1/dictionary/get?id=${id}`);
		} else {
			result = await request.get(`/api/v1/dictionary/all`);
		}
		
		if(result && result.config){
			return JSON.parse(result.config);
		} else return false;
	},
	async find(prefix){
		var list ;
		if(prefix){
			list = await request.get(`/api/v1/dictionary/getByPrefix?prefix=${prefix}`);
		} else {
			return {total:0,list:[]};
		}
		if(list && Array.isArray(list)){
			return {total:list.length,list:list.map(item=>{
				let config = JSON.parse(item.config);
				item.zid = item.id;
				item = {...item,...config};
				delete item.config;
				return item;
			})};
		} else return {total:0,list:[]};
	},
	async delete(id){
		if(id){
			var result = await request.delete(`/api/v1/dictionary/delete?id=${id}`);
			console.log(result);
		}
	},
	async create(id,dataObject){
		if(id){
			var result = await request.post(`/api/v1/dictionary/create`,{
				id,config:JSON.stringify(dataObject)
			});
			console.log(result);
		}
	},
	async update(id,dataObject){
		if(id){
			var result = await request.post(`/api/v1/dictionary/update`,{
				id,config:JSON.stringify(dataObject)
			});
			console.log(result);
		}
	},
	
};

export default request;