const md5 = require("blueimp-md5")
const Minio = require('minio')
const OSS = require('ali-oss');
const stream = require('stream')
var dlang = '简体';
var lang = window.localStorage.getItem('lang') || dlang;
const Common = {
  data(){
    return {
			lang: lang=="EN"?"EN":"CN",
			awardWorkStatusList:[],
			awardStageList:[],
			awardTypeList:[],
			creatorList:[],
			userData:{},
			CONFIG:{},
			isLogin:false,
			ossConfig:null,
			ossClient:null,
    }
  },
  async created(){
    if(!this.ossClient){this.initOSS();}
		await this.initData();
		document.getElementById("loading").className = 'hide';
		setTimeout(()=>{
			document.getElementById("loading").remove();
		},1001);
		this.$mount('#app');
  },
  methods:{
		initAwardType(work){
			var target = this.awardTypeList.find(item=>item.id == work.awardType);
			if(target){
				work.awardTypeCode = target.code;
				work.awardTypeName = target.name;
				work.awardTypeEname = target.ename;
			} else {
				work.awardTypeCode = "";
				work.awardTypeName = "";
			}
			return target;
		},
		initWorkData(item){
			try{
				item.content = JSON.parse(item.content);
				if(item.content.description){
					item.content.description = item.content.description.replace(/\n/g,"<br/>");
				}
			}catch(e){
				item.content = {};
			}
			let status = this.$root.awardWorkStatusList.find(temp => temp.status == item.awardWorkStatus);
			item.awardWorkStatusName = status ? status.name :"已入选";
			
			this.initAwardType(item);
		},
		async getCurrentUserWork(){
			var list = await this.request.get("/api/v1/awardWork/web/getCurrentUserWork");
			if(!list){list = [];}
			list = list.filter(item=>item.content)
			list.forEach(item=>{
				this.initWorkData(item);
			})
			return list;
		},
		async initZD(key){
			var list = await this.request.zd.get(key);
			this.$set(this,key,list||[]);
			console.log(key,list||[]);
		},
		async initData(){
			const token = window.localStorage.getItem("token");
			if(token){
				await this.initUserData()
			}
			await this.initZD('CONFIG')
			if(this.userData && this.userData.id == 3){
				this.CONFIG.onSubmit = true;
				this.CONFIG.onEdit = true;
			}
			await this.initZD('awardWorkStatusList')
			await this.initZD('awardStageList')
			await this.initZD('awardTypeList')
			await this.initZD('creatorList')
		},
		async initUserData(){
			var userData = await this.request.get("/api/v1/frontDeskUser/getCurrent");
			if(userData && userData.id){
				if(userData.config){
					try{
						userData.config = JSON.parse(userData.config);
					}catch(e){
						userData.config = {};
					}
				}
				this.$set(this,'userData',userData);
				this.isLogin = true;
				return userData;
			} else{
				window.localStorage.removeItem("token");
			}
			return false;
		},
		getData(key){
			return this[key];
		},
		prefixZero(num, n){
			return (Array(n).join(0) + num).slice(-n);
		},
		createWID(item){
			return item.awardTypeCode + this.prefixZero(item.awardStage,2) + this.prefixZero(item.id,6);
		},
		createUID(id){
			return (id*10000).toString(17).toUpperCase();
		},
		showViewer(list,index){
			var app = this.$root.$children[0];
			app.$refs.viewer.showViewer(list,index);
		},
		md5(text){
			return md5(text);
		},
		createId(val){
			return md5(val||(new Date()+"_"+Math.random()))
		},
		async initOSS(){
			var ossConfig = await this.request.get("/api/v1/oss/get");
			// if(result){ossConfig = result.data;}
			if(ossConfig){
				var ossClient
				if(ossConfig.provider == 'MINIO-OSS'){
					ossClient = new Minio.Client(ossConfig);
				} else {
					ossClient = new OSS(ossConfig);
				}
				this.ossConfig = ossConfig;
				this.ossClient = ossClient;
				console.log("ossClient inited!",ossClient,ossConfig);
			}
		},
    copy(data){
      return JSON.parse(JSON.stringify(data));
    },
    format(fmt='yyyy-MM-dd HH:mm:ss',date) { //author: meizz 
      if(!date){
        date = new Date();
      }
      var o = {
          "M+": date.getMonth() + 1, //月份 
          "d+": date.getDate(), //日 
          "h+": date.getHours(), //小时 
          "H+": this.getMakeZero(date.getHours()), //小时 
          "m+": date.getMinutes(), //分 
          "s+": date.getSeconds(), //秒 
          "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
          "S": date.getMilliseconds() //毫秒 
      };
      if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (var k in o){
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      }
      return fmt;
    },
    getMakeZero(s){
      return s < 10 ? '0' + s : s;
    },
    object2urlparams(data){
      return Object.keys(data).map(function (key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
      }).join("&");
    },
		checkEmail(email){
		  if(!email || !/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-_]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/.test(email)){
		    return false;
		  }
		  return true;
		},
		checkPassword(password){
		  if(!password || password.length<6){
		    return false;
		  }
			if(!/.*[0-9]{1,}.*/.test(password)){
				return false
			}
			if(!/.*[a-zA-Z]{1,}.*/.test(password)){
				return false
			}
		  return true;
		},
		checkPasswordConfirm(password,repassword){
		  if(!this.checkPassword(repassword) || password!=repassword){
		    return false;
		  }
		  return true;
		},
		checkCode(code){
		  if(!code || code.length!=4){
		    return false;
		  }
		  return true;
		},
		toPage(path = '/', params){
			if(this.$route.path == path){return ;}
			if(params){
				this.$router.push({name:path,params});
			} else{
				this.$router.push({path});
			}
			
		},
		async doUploadFile(file,configs = {}, cb){
			// provider: ALIYUN-OSS 和 MINIO-OSS
			if(!configs.maxSize){configs.maxSize = 128*1024*1024;} // 128M
			
			const fileName = file.name
			const mineType = file.type
			const fileSize = file.size
			const metadata = {
				"content-type": mineType,
				"content-length": fileSize
			}
			var ossConfig = this.ossConfig;
			var ossClient = this.ossClient;
			
			if(!ossConfig.path){ossConfig.path = "/";}
			if(ossConfig.path[ossConfig.path.length-1]!="/"){ossConfig.path += '/';} //尾端补齐 "/"
			if(!configs.uploadPath){configs.uploadPath = '';}
			if(configs.uploadPath && configs.uploadPath[configs.uploadPath.length-1]!="/"){configs.uploadPath.path += '/';} //尾端补齐 "/"
			
			const uploadPath = ossConfig.path + configs.uploadPath + this.createId()+'.'+(fileName.split(".").pop());
			const uploadPathWithHost = 'http://' + ossConfig.endPoint + '/' + ossConfig.bucket + "/" + uploadPath;
			console.log(ossConfig.bucket, uploadPath);
			if(ossConfig.provider == 'MINIO-OSS'){
				
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend =  (e) => {
					const dataurl = e.target.result
					//base64转blob
					const blob = this.toBlob(dataurl)
					//blob转arrayBuffer
					let reader2 = new FileReader()
					reader2.readAsArrayBuffer(blob)
				
					reader2.onload = function(ex) {
						//定义流
						let bufferStream = new stream.PassThrough();
						//将buffer写入
						bufferStream.end(new Buffer(ex.target.result));
						//上传
						ossClient.putObject(ossConfig.bucket, uploadPath, bufferStream, fileSize, metadata, (err, etag) => {
							console.log(err);
							cb && cb(err, etag, uploadPathWithHost);
						})
					}
				}
			} else {
				try {
					const result = await ossClient.put(
						uploadPath,file
					);
					cb && cb(null, result, result.url);
				} catch (e) {
					cb && cb(e, null, null);
				}
			}
			
		},
		toBlob (base64Data) {
			let byteString = base64Data
			if (base64Data.split(',')[0].indexOf('base64') >= 0) {
				byteString = atob(base64Data.split(',')[1]) // base64 解码
			} else {
				byteString = unescape(base64Data.split(',')[1])
			}
			// 获取文件类型
			let mimeString = base64Data.split(';')[0].split(":")[1] // mime类型
		
			// ArrayBuffer 对象用来表示通用的、固定长度的原始二进制数据缓冲区
			// let arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
			// let uintArr = new Uint8Array(arrayBuffer) // 创建视图
		
			let uintArr = new Uint8Array(byteString.length) // 创建视图
		
			for (let i = 0; i < byteString.length; i++) {
				uintArr[i] = byteString.charCodeAt(i)
			}
			// 生成blob
			const blob = new Blob([uintArr], {
				type: mimeString
			})
			// 使用 Blob 创建一个指向类型化数组的URL, URL.createObjectURL是new Blob文件的方法,可以生成一个普通的url,可以直接使用,比如用在img.src上
			return blob
		},
		n2c(num) {
			if (!/^\d*(\.\d*)?$/.test(num)) {
				return num;
			}
			var AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九");
			var BB = new Array("", "十", "百", "千", "万", "亿", "点", "");
			var a = ("" + num).replace(/(^0*)/g, "").split("."),
				k = 0,
				re = "";
			for (let i = a[0].length - 1; i >= 0; i--) {
				switch (k) {
						case 0:
								re = BB[7] + re;
								break;
						case 4:
								if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
										re = BB[4] + re;
								break;
						case 8:
								re = BB[5] + re;
								BB[7] = BB[5];
								k = 0;
								break;
				}
				if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
				if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
				k++;
			}
			if (a.length > 1) //加上小数部分(如果有小数部分) 
			{
				re += BB[6];
				for (let i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
			}
			var res = re.split("");
			if(res.length>1){
				if(res[0]=="一" && res[1]=="十"){
					res.shift();
					re = res.join("");
				}
			}
			return re;
		},
		createSliderHTML(list){
			let html = '<div>';
			return '<b>createSliderHTML</b>'
		},
		checkEditorialStage(step){
			if(!this.userData || !this.userData.role || this.userData.role != 'JUDGES' || !this.userData.config){
				this.$router.replace(`/`);return ;
			}
			let editorialStage = this.CONFIG.editorialStage || 1;
			
			if(this.userData.config.groupType == 'S' && step != 2){
				if(this.$route.path !== `/Editorial/Info/2`){
					this.$router.replace(`/Editorial/Info/2`);
				}
				return ;
			}
			
			if(step!=editorialStage && this.userData.config.groupType != 'S'){
				if(this.$route.path !== `/Editorial/Info/${editorialStage}`){
					this.$router.replace(`/Editorial/Info/${editorialStage}`);
				}
			}
		}
		
  }
};

export default Common;