<template>
  <div class="Images-Viewer" v-if="index>-1">
		<div class="bg" @click="index=-1"></div>
		<div class="views" :class="{isMax}">
			<div class="prev" @click="doPrev">
				<svg xmlns="http://www.w3.org/2000/svg" width="21" height="36" viewBox="0 0 33.895 60.79">
				  <path id="路径_755" data-name="路径 755" d="M8924.955,628.738l15.373-15.373,10.072-10.072-25.445-25.445" transform="translate(-8920.006 -572.898)" fill="none" stroke="#eee" stroke-linecap="round" stroke-linejoin="round" stroke-width="7"/>
				</svg>
			</div>
			<div class="content" @click="(isMax && ((isMax=!isMax)||true)) || (!isMax && (index=-1))" v-if="index>-1">
				<template v-if="list[index].type == 'video'">
					<video width="100%" height="100%" controls="controls">
						<source :src="list[index].path" type="video/ogg">
						<source :src="list[index].path" type="video/mp4">
					</video>
				</template>
				<template v-else>
					<svg v-show="loading" width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
						<g fill="none" fill-rule="evenodd">
							<g transform="translate(1 1)" stroke-width="2">
								<circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
								<path d="M36 18c0-9.94-8.06-18-18-18">
									<animateTransform
										attributeName="transform"
										type="rotate"
										from="0 18 18"
										to="360 18 18"
										dur="1s"
										repeatCount="indefinite"/>
								</path>
							</g>
						</g>
					</svg>
					<!-- <img v-show="loading" src="@/assets/common/loading_d.gif"/> -->
					<img v-show="!loading" :src="list[index].path" @click.stop="isMax=!isMax" @load="loadImage"/>
				</template>
				
			</div>
			<div class="next" @click="doNext">
				<svg xmlns="http://www.w3.org/2000/svg" width="21" height="36" viewBox="0 0 33.895 60.79">
				  <path id="路径_755" data-name="路径 755" d="M8924.955,628.738l15.373-15.373,10.072-10.072-25.445-25.445" transform="translate(-8920.006 -572.898)" fill="none" stroke="#eee" stroke-linecap="round" stroke-linejoin="round" stroke-width="7"/>
				</svg>
			</div>
		</div>
		<div class="close" @click="index=-1"></div>
		<!-- <div v-for="item in list" :key="item.id"><img :src="item.path" /></div> -->
	</div>
</template>

<script>
export default {
  name: 'Images-Viewer',
  props: {
		
  },
  data(){
    return {
			loading:false,
			list:[],
			index:-1,
			isMax:false
    }
  },
	watch:{
		index(){
			if(this.index == -1){
				window.document.onkeydown = undefined;
			}
		}
	},
	async mounted(){
		
		
	},
  methods:{
		loadImage(){
			this.loading = false;
		},
		doPrev(){
			this.loading = true;
			this.$nextTick(()=>{
				if(this.index > 0 ){
					this.index --;
				} else {
					var index = this.list.length - 1;
					if(this.index == index){
						this.loading = false;
					} else {
						this.index = index;
					}
				}
			})
			
		},
		doNext(){
			this.loading = true;
			this.$nextTick(()=>{
				if(this.index < this.list.length - 1){
					this.index ++;
				} else {
					var index = this.list.length?0:-1;
					if(this.index == index){
						this.loading = false;
					} else {
						this.index = index;
					}
				}
			})
			
		},
		showViewer(list,index){
			this.loading = true;
			this.isMax = false;
			
			this.list = list;
			
			let isCDN = !!(window.location.host.indexOf("apd-cdn")===0);
			// isCDN = true;
			if(isCDN){
				this.list.forEach(item=>{
					item.path = item.path.replace("apd-resource.oss-cn-shenzhen.aliyuncs.com","apd-resource.oss-accelerate.aliyuncs.com");
				})
			}
			
			window.document.onkeydown = e => {//事件对象兼容
				let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
				console.log(e1 , e1.keyCode);
				if (e1 && e1.keyCode == 37) {
					this.doPrev();
				} else if (e1 && e1.keyCode == 39) {
					this.doNext();
				} else if (e1 && e1.keyCode == 27) {
					this.index = -1;
				}
			}
			
			
			this.$nextTick(()=>{
				
				if(!index && list.length){ this.index = 0; }
				else if(!list || !list.length){ this.index = -1; }
				else {this.index = index;}
				if(index>list.length){ this.index = list.length=1; }
			});
			
			
			
			
		}
  } 
}
</script>

<style scoped lang="less">
.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.Images-Viewer{
	.noselect();
	.bg{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, .9);
		z-index: 1001;
		cursor: zoom-out;
	}
	.close{
		position: fixed;
		top:10px;
		right:10px;
		width:30px;
		height:30px;
		z-index: 1002;
		&::after,&::before{
			cursor: pointer;
			content:" ";
			display: block;
			width:30px;
			height:4px;
			top:25px;
			right:10px;
			background-color: #fff;
			position: fixed;
			border-radius: 8px;
			
			transform:rotate(45deg);
			-ms-transform:rotate(45deg); 	/* IE 9 */
			-moz-transform:rotate(45deg); 	/* Firefox */
			-webkit-transform:rotate(45deg); /* Safari 和 Chrome */
			-o-transform:rotate(45deg); 	/* Opera */
		}
		&::before{
			transform:rotate(135deg);
			-ms-transform:rotate(135deg); 	/* IE 9 */
			-moz-transform:rotate(135deg); 	/* Firefox */
			-webkit-transform:rotate(135deg); /* Safari 和 Chrome */
			-o-transform:rotate(135deg); 	/* Opera */
		}
	}
	
	.views{
		width: 100%;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		z-index: 1002;
		position: fixed;
		left: 0;
		text-align: center;
		top: 0%;
		border-radius: 4px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: zoom-out;
		padding: 15px;
		font-size: 70px;
		color: #FFF;
		
		.prev, .next {
			cursor: pointer;
			height: 36px;
			width: 21px;
			line-height: 36px;
			flex-shrink: 0;
			// &::after{
			// 	content:" ";
			// 	display: block;
			// 	width: 100%;
			// 	height:100%;
			// 	background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMy44OTUiIGhlaWdodD0iNjAuNzkiIHZpZXdCb3g9IjAgMCAzMy44OTUgNjAuNzkiPgogIDxwYXRoIGlkPSLot6/lvoRfNzU2IiBkYXRhLW5hbWU9Iui3r+W+hCA3NTYiIGQ9Ik04MTEzLjE2Myw1NzcuODQ4bC0yNS40NDUsMjUuNDQ1LDI1LjQ0NSwyNS40NDUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04MDg0LjIxOCAtNTcyLjg5OCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2Y5ZjlmOSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjciLz4KPC9zdmc+Cg==) no-repeat center center / 100% 100%;
			// }
		}
		.prev{
			&::after,svg{
				transform:rotate(180deg)
				// border-left: 25px solid #fff;
				// border-right: 0;
			}
		}
		.content{
			flex-shrink: 1;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			img,svg{
				max-width: 100%!important;
				max-height: 100%!important;
				border-radius: 4px;
				display: block;
				margin: 0 auto;
				cursor: zoom-in;
			}
		}
		
		&.isMax{
			padding: 0 0 0 0;
			overflow-x: auto;
			overflow-y: auto;
			.prev, .next {
				display: none;
			}
			.content{
				align-items: flex-start;
				img{
					width: unset!important;
					height: unset!important;
					max-height: unset!important;
					/* max-width: unset!important; */
					cursor: zoom-out;
				}
			}
		}
		
	}
	
}
</style>
