<template>
  <div id="app" :class="lang">
    <!-- <Loading :loading="loading"></Loading> -->
		<images-viewer ref="viewer"></images-viewer>
    <transition name="fade"><router-view></router-view></transition>
  </div>
  
</template>

<script>
// import Loading from './components/Loading.vue'

export default {
  name: 'App',
  components: {
    // Loading,
		
  },
  data(){
		var dlang = '简体';
		var lang = window.localStorage.getItem('lang') || dlang;
    return {
      loading:true,   
			lang: lang=="EN"?"EN":"CN"
		}
  },
  mounted () {
    setTimeout(() => {
      this.loading = false;
    }, 200);
  }
}
</script>

<style>

#app {
  height:100%;
}

.fade-enter-active,
.fade-leave-active {
 transition: all .2s ease;
}
 
.fade-enter,
.fade-leave-active {
 opacity: 0;
}

.fold-enter-active {
  animation-name: fold-in;
  animation-duration: .5s;
}
.fold-leave-active {
  animation-name: fold-out;
  animation-duration: .5s;
}
@keyframes fold-in {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  50% {
    transform: translate3d(0, 50%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fold-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50px, 0, 0);
  }
}
		
</style>
